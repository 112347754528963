import React, { Component } from "react";
import { Switch, Route, withRouter } from "react-router-dom";

import { inject, observer } from "mobx-react";

import PrivateRoute from "./components/PrivateRoute";
import Login from "./components/Login.js";
import CreateNewPassword from "./components/CreateNewPassword.js";
import ForgotPassword from "./components/ForgotPassword.js";
import Landing from "./pages/Landing";
import Splash from "./pages/Splash";
import Register from "./components/Register";
import Reregistration from "./components/Reregistration";
import Agent from "./pages/agent/Agent";
import Venue from "./pages/venue/Venue";
import Staff from "./pages/staff/Staff";
import Musician from "./pages/musician/Musician";
import AccountSetting from "./pages/setting/AccountSetting";
import ChangePassword from "./pages/setting/ChangePassword";
import MusicianProfile from "./pages/profile/MusicianProfile";
import AgentProfile from "./pages/profile/AgentProfile";
import VenueProfile from "./pages/profile/VenueProfile";
import ActProfile from "./pages/profile/ActProfile";
import PublicProfile from "./pages/profile/PublicProfile";
import ManageMYOB from "./pages/ManageMYOB/ManageMYOB";

import Support from "./pages/profile/Support";
import Billing from "./pages/billing/Billing";
import PaymentHistory from "./pages/billing/PaymentHistory";

import Welcome from "./pages/Welcome";
import Confirmation from "./pages/Confirmation";
import ResendConfirmation from "./pages/ResendConfirmation";

import { createMuiTheme, withTheme, MuiThemeProvider } from "@material-ui/core/styles";
import EditMusician from "./pages/musician/EditMusician";
import EditProduction from "./pages/production/EditProduction";
import Production from "./pages/production/Production";
import EditDj from "./pages/dj/EditDj";
import Dj from "./pages/dj/Dj";
import EditAgent from "./pages/agent/EditAgent";
import EditPrivateHire from "./pages/PrivateHire/EditPrivateHire"
import EditAct from "./pages/act/EditAct";
import EditStaff from "./pages/staff/EditStaff";
import EditVenue from "./pages/venue/EditVenue";
import SongList from "./pages/songList/SongList";
import SongListView from "./pages/songList/SongListView";
import SetListNew from "./pages/setList/SetListNew";
import SetListEdit from "./pages/setList/SetListEdit";
import SetListView from "./pages/setList/SetListView";
import SetList from "./pages/setList/SetList";

import MyAgents from "./pages/MyAgents/MyAgents";

import Acts from "./pages/act/Acts";
import Venues from "./pages/venue/Venues";

import SearchPage from "./pages/SearchPage";

import EditMusicianBooking from "./pages/booking/EditMusicianBooking";
import MusicianBookings from "./pages/booking/MusicianBookings";
import Invoice from "./pages/invoice/Invoice";
import Booking from "./pages/booking/Booking";
import MyCalendar from "./pages/booking/MyCalendar";
import AgentGig from "./pages/booking/AgentGig";
import PrivateHireGig from "./pages/booking/PrivateHireGig";
import StaffGig from "./pages/booking/StaffGig";
import BookingProcess from "./pages/booking/BookingProcess";
import BookingEdit from "./pages/booking/BookingEdit";

import Calendar from "./pages/calendar/Calendar";

import Organisation from "./pages/organization/Organization";
import OrganisationEdit from "./pages/organization/OrganizationEdit";
import StateEdit from "./pages/organization/StateEdit";
import RegionEdit from "./pages/organization/RegionEdit";

import AgentVenue from "./pages/agent/AgentVenue";

import ActRoster from "./pages/agent/ActRoster";

import InvoicePdf from "./pages/invoice/InvoicePdf";
import InvoiceQuickBooks from "./pages/invoice/InvoiceQuickBooks";

import { default as MusicianVenues } from "./pages/musician/Venues";

import AgentConsultant from "./pages/agent/AgentConsultant";
import AgentAgency from "./pages/agent/AgentAgency";

import StaffActRoster from "./pages/staff/StaffActRoster";

import PrivacyPolicy from "./pages/privacyPolicy/privacyPolicy";
import PrivacyPolicyMobile from "./pages/privacyPolicy/privacyPolicyMobile";
import EmbedGig from "./pages/embed/EmbedGig";
import EmbedGigText from "./pages/embed/EmbedGigText";
import EmbedGigGrid from "./pages/embed/EmbedGigGrid";

import Reports from "./pages/reports/Reports";
import Messages from "./pages/messages/Messages";

import StaffAgentRoster from "./pages/staff/StaffAgentRoster";

import Entertainment from "./pages/entertainment/Entertainment";

import EntertainmentNextEvent from "./pages/entertainment/EntertainmentNextEvent";

import EntertainmentNext4Event from "./pages/entertainment/EntertainmentNext4Event";


import "./App.css";
// import Insurance from "./pages/insurance/Insurance";
import HubTrade from "./pages/hubtrade/HubTrade";
import Applesitehosting from "./pages/Applesitehosting"
import Promotion from "./pages/promotion/Promotion";
import EditPromotion from "./pages/promotion/EditPromotion";
import Unauthorized from "./pages/error/Unauthorized";
import PrivateBooking from "./pages/privateBooking/PrivateBooking";
import BookingClaimed from "./pages/booking/BookingClaimed";
import TermOfService from "./pages/termOfService/TermOfService";
import TermOfServiceMobile from "./pages/termOfService/TermOfServiceMobile";
import GigPromotionList from "./pages/GigPromotion/GigPromotionList";
import EditGigPromotion from "./pages/GigPromotion/EditGigPromotion";
import HotgiggityLive from "./pages/Live/HotgiggityLive";
import BigsoundLive from "./pages/Live/BigsoundLive";
import MessageDetail from "./pages/messages/MessageDetail";
import Notification from "./pages/notification/Notification";
import Admin from "./pages/admin/Admin";
import SocialPosts from "./pages/GigPromotion/SocialPosts";
import SocialPostsDetail from "./pages/GigPromotion/SocialPostsDetail";
import LegacyUsers from "./pages/legacyusers/LegacyUsers";
import ManageXero from "./pages/ManageXero/ManageXero";
import VenueActFavourites from "./pages/agent/VenueActFavourites.js"


// import Amplify from "aws-amplify";
const theme = createMuiTheme({
    palette: {
        primary: {
            light: "#ffcc4d",
            main: "#ff0c9c",
            dark: "#b20069",
            contrastText: "#ffffff"
        },
        secondary: {
            light: "#4e4e4e",
            main: "#262626",
            dark: "#000000",
            contrastText: "#ffffff"
        },
    },
    typography: {
        // Use the system font.
        useNextVariants: true,
        fontFamily: "OpenSans, sans-serif",
        h5: {
            fontSize: '1.2rem !important',
            lineHeight: 1.1
          },
    },
    error: {
        main: "#ff0000",
        light: "#ff0000",
        dark: "#ff0000",
        contrastText: "#ff0000"
    },
    overrides: {
        MuiExpansionPanelSummary: {
            root: {
                padding: 0,
            }
          },
        MuiInput: {
            root: {
                "&$focused": {
                    boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.2)",
                    transform: "transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;"
                },
                backgroundColor: "#eee",
                border: 0,
                // padding: 7,
                borderRadius: 4,
                "&:before": {
                    border: "none !important"
                }
            },
            input: {
                padding: "10px 10px 12px"
            },
            inputMarginDense: {
                paddingTop: 12
            },
            underline: {
                "&:before": {
                    borderBottom: "none"
                },
                "&:after": {
                    borderBottom: "none"
                }
            },
            error: {
                backgroundColor: "#ffcdd2"
            }
        },
        MuiInputLabel: {
            formControl: {
                transform: "translate(0, 0px) scale(0.9)"
            },
            marginDense: {
                transform: "translate(0, 0px) scale(0.9)"
            },
            shrink: {
                transform: "translate(0, 0px) scale(0.9)"
            }
        },
        MuiInputAdornment: {
            root: {
                maxHeight: "auto"
            },
            positionStart: {
                marginRight: 0,
                borderRadius: "4px 0 0 4px",
                padding: "21px 10px 21px 10px",
                backgroundColor: "#ccc"
            }
        },
        MuiPaper: {
            elevation2: {
                boxShadow: "0 0px 3px rgba(0, 0, 0, 0.2)"
            },
            elevation4: {
                boxShadow: "0px 5px 4px -1px rgb(0 0 0)"
            }

        },
        MuiTableRow: {
            head: {
                height: 40
            }
        },
        MuiToolbar :{
            root:{
                "& h6":{
                    color:"#ffffff"
                }
            }
        },
        MuiCardHeader:{
                root:{
                    "& h6":{
                        color:"#ffffff"
                    }
                }
        },
        MuiTableSortLabel:{
            root:{
                "& h6":{
                    "&.head-title":{
                        color:"rgba(0, 0, 0, 0.87)"
                    }
                }
            }
        }
    }
});

// import { BrowserRouter as Router, Route, Link } from "react-router-dom";
@inject("userStore", "commonStore", "profileStore", "notificationStore", "templateStore", "billingStore", "authStore")
@withRouter
@observer
class App extends Component {
    constructor(props) {
        super(props);
        this.props.notificationStore.setHistory(this.props.history);
        this.props.templateStore.setHistory(this.props.history);
        if (!this.props.commonStore.token) {
            this.props.commonStore.setAppLoaded(true);
        }
        // Amplify.configure({
        //     Auth: {
        //       region: process.env.REACT_APP_REGION,
        //       userPoolId: process.env.REACT_APP_USER_POOL_ID,
        //       userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
        //     },
        //   });
    }
    // useEffect = () => {
    //     console.log("masuk use effect")
    //     this.props.authStore.refreshToken();
    // }
    // componentDidUpdate(){
    //     console.log("componentDidUpdate");
    //     this.props.authStore.refreshToken();
    // }

    async componentDidMount() {
        this.props.authStore.refreshToken();
        const { match } = this.props;
        
        if (this.props.commonStore.token) {
            // this.props.commonStore.setAppLoaded(false);
            var url = window.location.href
            var array = url?.split("/")
            if(array && array.length){
                if(array[3] === "embedgrid" || array[3] === "embed"){
                    this.props.profileStore.loadProfiles();
                }else{
                    await this.props.profileStore.loadProfiles();
                }
            }else{  
                await this.props.profileStore.loadProfiles();
            }
           
            //.then(() => this.props.billingStore.getPaymentAccesses(this.props.userStore.currentUser.id))
            this.props.commonStore.setAppLoaded(true);
           
            
           
            this.props.billingStore.loadAccess();
        }
    }

    render() {
        if (this.props.commonStore.appLoaded) {
            return (
                <MuiThemeProvider theme={theme}>
                    <div className="App">
                        <Switch>
                            <Route path="/live/:path/:id" component={HotgiggityLive} />
                            <Route path="/bigsound/:path/:id" component={BigsoundLive} />
                            <Route path="/app/sign-in" component={Login} />
                            <Route path="/sign-in" component={Login} />
                            <Route path="/createnewpassword" component={CreateNewPassword} />
                            <Route path="/forgotpassword" component={ForgotPassword} />
                            <Route path="/sign-up" component={Register} />
                            <Route path="/app/sign-up" component={Register} />
                            <Route path="/re-registration" component={Reregistration} />
                            <Route path="/app/re-registration" component={Reregistration} />
                            <Route path="/musician/songs" component={SongListView} />
                            <Route path="/policy" component={PrivacyPolicy} />
                            <Route path="/policymobile" component={PrivacyPolicyMobile} />
                            <Route path="/tos" component={TermOfService} />
                            <Route path="/tosmobile" component={TermOfServiceMobile} />
                            <Route path="/embed/:id" component={EmbedGig} />
                            <Route path="/embedtext/:id" component={EmbedGigText} />
                            <Route path="/embedgrid/:id" component={EmbedGigGrid} />

                            <PrivateRoute path="/admin/legacy" component={LegacyUsers} key="legacyusers" sidebarKey="legacyusers" />
                            <PrivateRoute path="/admin/users" component={Admin} key="users" sidebarKey="users" />
                            <PrivateRoute path="/admin" component={Musician} sidebarKey="dashboard" />

                            <PrivateRoute path="/welcome/:role?/:profileId?" component={Welcome} />
                            <Route path="/confirmation" component={Confirmation} />
                            <Route path="/resendconfirmationtoken" component={ResendConfirmation} />
                            <PrivateRoute
                                requiredSubscription={"agent venue"}
                                path="/account/setting/:tab?"
                                component={AccountSetting}
                                sidebarKey="accountSetting"
                            />

                            <PrivateRoute
                                requiredSubscription={"agent venue"}
                                path="/myobSetting/:activeTab?"
                                component={ManageMYOB}
                                sidebarKey="myobSetting"
                            />
                            <PrivateRoute
                                path="/xeroSetting/:activeTab?"
                                component={ManageXero}
                                sidebarKey="xeroSetting"
                            />
                            <PrivateRoute requiredSubscription={"agent venue"} path="/account/changepassword" component={ChangePassword} />

                            {/* <PrivateRoute path="/musician/booking/new" component={EditMusicianBooking} /> */}
                            <PrivateRoute path="/notifications" component={Notification} key="Notifications" sidebarKey="notifications" />
                            <PrivateRoute path="/musician/booking/claimed" component={BookingClaimed} key="myGigs" />
                            <PrivateRoute path="/musician/booking/:id/:step/:bookingRequestId?" component={EditMusicianBooking} sidebarKey="myGigs" />
                            <PrivateRoute path="/musician/gig/:tab?/:id?" component={MusicianBookings} sidebarKey="myGigs" />
                            <PrivateRoute path="/musician/calendar" component={MyCalendar} sidebarKey="calendar" />
                            <PrivateRoute path="/musician/profile" component={MusicianProfile} />
                            <PrivateRoute path="/musician/edit/:new?" component={EditMusician} sidebarKey="myProfile" />
                            <PrivateRoute path="/musician/songlist" component={SongList} sidebarKey="mySongList" />
                            <PrivateRoute path="/musician/setlist/new" component={SetListNew} />
                            <PrivateRoute path="/musician/setlist/:id/edit" component={SetListEdit} />
                            <PrivateRoute path="/musician/setlist/:id/view" component={SetListView} />
                            <PrivateRoute path="/musician/setlist" component={SetList} sidebarKey="mySetLists" />
                            <PrivateRoute path="/musician/agents" component={MyAgents} sidebarKey="myAgents" />
                            <PrivateRoute path="/musician/venues" component={MusicianVenues} sidebarKey="venues" />
                            <PrivateRoute path="/musician" component={Musician} sidebarKey="dashboard" />
                            <PrivateRoute path="/socialposts" component={SocialPosts} sidebarKey="socialposts" />
                            <PrivateRoute path="/post/detail/:bookingId" component={SocialPostsDetail} sidebarKey="socialposts" />

                            <PrivateRoute
                                path="/production/edit/:new?"
                                component={EditProduction}
                                sidebarKey="myProfile"
                                stillOnDev
                                onDevTitle="Production Crew"
                            />
                            <PrivateRoute path="/production" component={Production} sidebarKey="myProfile" stillOnDev onDevTitle="Production Crew" />
                            <PrivateRoute path="/dj/edit/:new?" component={EditDj} sidebarKey="myProfile" />
                            <PrivateRoute path="/dj" component={Dj} sidebarKey="dashboard" />

                            <PrivateRoute requiredSubscription={"agent"} path="/agent/venues" component={AgentVenue} sidebarKey="venues" />
                            <PrivateRoute requiredSubscription={"agent"} path="/agent/actfavorite/venues/:id?/:venueName?" component={VenueActFavourites} sidebarKey="venues" />
                            <PrivateRoute requiredSubscription={"agent"} path="/agent/profile" component={AgentProfile} />
                            <PrivateRoute requiredSubscription={"agent"} path="/agent/calendar" component={MyCalendar} sidebarKey="calendar" />
                            <PrivateRoute
                                requiredSubscription={"agent"}
                                path="/agent/consultant"
                                component={AgentConsultant}
                                sidebarKey="consultant"
                            />
                            <PrivateRoute path="/privatehire/:type/edit/:new?/:role?" component={EditPrivateHire} sidebarKey="myProfile" />
                            <PrivateRoute path="/privatehire/edit/:new?/:role?" component={EditPrivateHire} sidebarKey="myProfile" />
                            <PrivateRoute requiredSubscription={"privatehire"} path="/privatehire/gig/:tab?" component={PrivateHireGig} sidebarKey="myGigs" />
                            <PrivateRoute requiredSubscription={"agent"} path="/agent/agency" component={AgentAgency} sidebarKey="agency" />
                            <PrivateRoute requiredSubscription={"agent"} path="/agent/gig/:tab?/:id?" component={AgentGig} sidebarKey="myGigs" />
                            <PrivateRoute path="/agent/edit/:new?/:role?" component={EditAgent} sidebarKey="myProfile" />
                            
                            <PrivateRoute
                                requiredSubscription={"agent"}
                                path="/agent/actroster/:tab?"
                                component={ActRoster}
                                sidebarKey="manage_actRoster"
                            />
                            <PrivateRoute requiredSubscription={"agent"} path="/agent" component={Agent} sidebarKey="dashboard" />

                            <PrivateRoute requiredSubscription={"venue"} path="/staff/calendar" component={MyCalendar} sidebarKey="calendar" />
                            <PrivateRoute requiredSubscription={"venue"} path="/staff/agents" component={StaffAgentRoster} sidebarKey="myAgents" />
                            <PrivateRoute
                                requiredSubscription={"venue"}
                                path="/staff/acts"
                                component={StaffActRoster}
                                sidebarKey="manage_actRoster"
                            />
                            <PrivateRoute requiredSubscription={"venue"} path="/staff/profile" component={VenueProfile} />
                            <PrivateRoute requiredSubscription={"venue"} path="/staff/gig/:tab?/:id?" component={StaffGig} sidebarKey="myGigs" />
                            <PrivateRoute path="/staff/edit/:new?" component={EditStaff} sidebarKey="myProfile" />
                            <PrivateRoute path="/staff/:type/edit/:new?" component={EditStaff} sidebarKey="myProfile" />
                            <PrivateRoute requiredSubscription={"venue"} path="/staff" component={Staff} sidebarKey="dashboard" />

                            <PrivateRoute requiredSubscription={"public"} path="/venues/:id/edit/:tab?" component={EditVenue} sidebarKey="venues" />

                            <PrivateRoute requiredSubscription={"venue"} path="/venues/new/:regionId" component={EditVenue} sidebarKey="venues" />
                            <PrivateRoute requiredSubscription={"venue"} path="/venues/new" component={EditVenue} sidebarKey="venues" />
                            <PrivateRoute requiredSubscription={"public"} path="/venues/:id/edit/:tab?" component={EditVenue} sidebarKey="venues" />
                            <PrivateRoute requiredSubscription={"venue"} path="/venues" component={Venues} sidebarKey="venues" />
                            <PrivateRoute requiredSubscription={"venue"} path="/venue/profile" component={VenueProfile} sidebarKey="venues" />
                            <PrivateRoute path="/venue/edit/:new?" component={EditVenue} sidebarKey="venues" />
                            <PrivateRoute requiredSubscription={"venue"} path="/venue" component={Venue} sidebarKey="dashboard" />

                            <PrivateRoute
                                requiredSubscription={"agent venue"}
                                path="/myact/edit/:id?/:tab?"
                                component={EditAct}
                                sidebarKey="myActs"
                            />
                            <PrivateRoute
                                requiredSubscription={"agent venue"}
                                path="/myact/create"
                                component={EditAct}
                                sidebarKey="myActs"
                            />
                            <PrivateRoute
                                requiredSubscription={"agent venue"}
                                path="/myact/profile/:id?"
                                component={ActProfile}
                                sidebarKey="myActs"
                            />
                            <PrivateRoute requiredSubscription={"agent venue"} path="/myact" component={Acts} sidebarKey="myActs" />

                            <PrivateRoute
                                requiredSubscription={"agent venue"}
                                path="/booking/:id/:step"
                                component={BookingEdit}
                                sidebarKey="myGigs"
                            />
                            <PrivateRoute requiredSubscription={"agent venue"} path="/booking/new" component={BookingProcess} sidebarKey="booking" />
                            <PrivateRoute requiredSubscription={"agent venue"} path="/booking" component={Booking} sidebarKey="booking" />

                            <PrivateRoute
                                requiredSubscription={"agent venue"}
                                path="/privateBooking/:id?/:step?/:bookingRequestId?"
                                component={PrivateBooking}
                                sidebarKey="booking"
                            />

                            <PrivateRoute requiredSubscription={"agent venue"} path="/calendar/:id/:profileType" component={Calendar} />

                            <PrivateRoute
                                requiredSubscription={"venue"}
                                path="/organisation/:id/state/:stateId/region/:regionId/edit"
                                component={RegionEdit}
                            />
                            <PrivateRoute
                                requiredSubscription={"venue"}
                                path="/organisation/:id/state/:stateId/edit"
                                component={StateEdit}
                                sidebarKey="organisation"
                            />
                            <PrivateRoute requiredSubscription={"venue"} path="/organisation/:id/edit" component={OrganisationEdit} />
                            <PrivateRoute requiredSubscription={""} path="/organisation/new" component={OrganisationEdit} />
                            <PrivateRoute requiredSubscription={""} path="/organisation" component={Organisation} sidebarKey="organisation" />

                            <PrivateRoute
                                requiredSubscription={"musician agent venue"}
                                path="/invoice/:invoiceId/detail"
                                component={InvoicePdf}
                                sidebarKey="financial"
                            />
                            <PrivateRoute
                                requiredSubscription={"musician agent venue"}
                                path="/invoice/:invoiceId/quickbooks"
                                component={InvoiceQuickBooks}
                                sidebarKey="financial"
                            />
                            <PrivateRoute requiredSubscription={"agent venue"} path="/invoice/:stateId?" component={Invoice} sidebarKey="invoices" />

                            <PrivateRoute requiredSubscription={"agent venue"} path="/reports" component={Reports} sidebarKey="reports" />

                            <PrivateRoute path="/messages/:conversationId" component={MessageDetail} sidebarKey="messages" />
                            <PrivateRoute path="/messages/new/" component={MessageDetail} sidebarKey="messages" />
                            <PrivateRoute path="/messages/invoice/" component={MessageDetail} sidebarKey="messages" />
                            <PrivateRoute path="/messages" component={Messages} sidebarKey="messages" />

                            {/* <PrivateRoute requiredSubscription={"agent venue"} path="/insurance" component={Insurance} sidebarKey="insurance" /> */}

                            <PrivateRoute requiredSubscription={"agent venue"} path="/hubtrade" component={HubTrade} sidebarKey="hubTrade" />

                            <PrivateRoute
                                requiredSubscription={"agent venue"}
                                path="/promotion/:id/edit"
                                component={EditPromotion}
                                sidebarKey="promotion"
                            />
                            <PrivateRoute
                                requiredSubscription={"agent venue"}
                                path="/promotion/new"
                                component={EditPromotion}
                                sidebarKey="promotion"
                            />

                            <PrivateRoute
                                requiredSubscription={"agent venue"}
                                path="/promotion/:activeTab?"
                                component={Promotion}
                                sidebarKey="promotion"
                            />

                            <PrivateRoute
                                requiredSubscription={"venue"}
                                path="/gig/promotion/:id/edit"
                                component={EditGigPromotion}
                                sidebarKey="gigPromotion"
                            />

                            <PrivateRoute
                                requiredSubscription={"venue"}
                                path="/gig/promotion/new"
                                component={EditGigPromotion}
                                sidebarKey="gigPromotion"
                            />

                            <PrivateRoute
                                requiredSubscription={"venue"}
                                path="/gig/promotion/:activeTab?"
                                component={GigPromotionList}
                                sidebarKey="gigPromotion"
                            />

                          
                            <PrivateRoute path="/search" component={SearchPage} key="search" sidebarKey="search" />

                            <PrivateRoute path="/billing/payment" component={Billing} sidebarKey="payment" />
                            <PrivateRoute path="/billing/paymentHistory" component={PaymentHistory} sidebarKey="paymentHistory" />

                            <Route exact path="/unauthorized" component={Unauthorized} />
                            
                            
                            <Route path="/.well-known/apple-app-site-association" component={Applesitehosting} />
                            <Route path="/profile/:id/songs" component={SongListView} />
                            <Route path="/profile/:id/entertainment" component={Entertainment} />

                            <Route path="/profile/:id/nextevent/entertainment" component={EntertainmentNextEvent} />
                            <Route path="/profile/:id/upnextlive/entertainment" component={EntertainmentNext4Event} />
                            
                            <Route path="/profile/:id" component={PublicProfile} />
                            
                            <Route exact path="/support" component={Support} />
                            <Route exact path="/:id/songs" component={SongListView} />
                            <Route exact path="/:id/entertainment" component={Entertainment} />
                            <Route exact path="/:id" component={PublicProfile} />
                            {/* <Route exact path="/invoice/:stateId" component={Invoice} sidebarKey="invoices"  /> */}

                            <Route path="/" component={Landing} />
                        </Switch>
                    </div>
                </MuiThemeProvider>
            );
        }
        return (
            <MuiThemeProvider theme={theme}>
                <Splash />
            </MuiThemeProvider>
        );
    }
}
export default withTheme(theme)(App);
