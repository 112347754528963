import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import agent from "../../agent";
import { withRouter } from "react-router-dom";

import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import DashboardBigInformationWidget from "../../components/dashboard/DashboardBigInformationWidget";
import DashboardBigInformationWidgetSingle from "../../components/dashboard/DashboardBigInformationWidgetSingle";
import DashboardSmallInformationWidget from "../../components/dashboard/DashboardSmallInformationWidget";
import DashboardSubscriptionInformation from "../../components/dashboard/DashboardSubscriptionInformation";
import MainContainer from "../../components/MainContainer";
import GigsWidget from "../../components/widget/GigsWidget";
import UpcomingBookingMusician from "../../components/dashboard/UpcomingBookingMusician";
import DashboardFilter from "../../components/dashboard/DashboardFilter";
import Heading from "../../components/Heading";
import { ProfileType } from "../../types/enum"
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Loading from "../../components/loadingSpinner/Loading";
import moment from "moment"
import jwtDecode from "jwt-decode";
const ENABLE_SUBSCRIPTION = process.env.REACT_APP_ENABLE_SUBSCRIPTION === "true";

@inject("userStore", "templateStore", "profileStore", "commonStore")
@withRouter
@observer
class Musician extends Component {
    state = {
        data: {},
        startDate: null,
        endDate: null,
        todaysGigs: [],
        isLoading: true
    };
    currentUserId = 0;
    offset = moment(new Date()).utcOffset()
    formatDate = date => {
        let d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return [year, month, day].join("-");
    };
    gotoDashboard(profileType) {
        switch (profileType) {
            case 1:
                this.props.history.replace("/musician");
                break;
            case 2:
                this.props.history.replace("/agent");
                break;
            case 3:
                this.props.history.replace("/staff");
                break;
            case 6:
                this.props.history.replace("/production");
                break;
            case 7:
                this.props.history.replace("/dj");
                break;
            case 8:
                this.props.history.replace("/agent");
                break;
            case 9:
                this.props.history.replace("/privatehire/gig");
                break;
    }
    }
    componentDidMount = () => {
        const currentUser = this.props.profileStore.getCurrentProfile();
        this.currentUserId = currentUser.id;
        if(currentUser.type !== ProfileType.Staff.ordinal){
            this.gotoDashboard(currentUser.type)
        }
        let date = new Date();
        let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        firstDay = this.formatDate(firstDay) + " 00:00:00";
        lastDay = this.formatDate(lastDay) + " 23:59:59.999";

        // this.setState({ isLoading: true });
        this.handleMusicianLoad(this.currentUserId, firstDay, lastDay)

    };

    onCustomMonthChange = (startDate, endDate) => {
        this.setState({ startDate: startDate, endDate: endDate });

        let firstDay = this.formatDate(startDate) + " 00:00:00";
        let lastDay = this.formatDate(endDate) + " 23:59:59.999";

        this.setState({ firstDay: firstDay, lastDay: lastDay }, () => {
            this.handleMusicianLoad(this.currentUserId, firstDay, lastDay)
        });
    };

    onCustomStartDateChange = value => {
        this.setState({ startDate: value });

        if (this.state.endDate) {
            let firstDay = this.formatDate(value) + " 00:00:00";
            let lastDay = this.formatDate(this.state.endDate) + " 23:59:59.999";

            this.setState({ isLoading: true });
            this.handleMusicianLoad(this.currentUserId, firstDay, lastDay)
        }
    };

    onCustomEndDateChange = value => {
        this.setState({ endDate: value });

        if (this.state.startDate) {
            let firstDay = this.formatDate(this.state.startDate) + " 00:00:00";
            let lastDay = this.formatDate(value) + " 23:59:59.999";

            this.setState({ isLoading: true });
            this.handleMusicianLoad(this.currentUserId, firstDay, lastDay)
        }
    };

    handleMusicianLoad = (id,start,end ) =>{
        Promise.all([
            agent.Profile.getMusicianDashboardMain(id, start, end),
            agent.Profile.getMusicianDashboardRequests(id, start, end)
        ]).then(result => {
            var contain = result[0];
            contain.newGigAlerts = result[1];
            var todayGigs = []
            contain?.upcomingBookings?.forEach((gig) => {
                var dateStart = moment(gig.dateStart)
                var dateEnd = moment(gig.dateEnd)
                gig.dateStart = dateStart.subtract((parseFloat(gig.utcOffset) + dateStart.utcOffset()),'minute')
                gig.dateEnd = dateEnd.subtract(parseFloat(gig.utcOffset) + dateEnd.utcOffset(),'minute'); 
            
                var dstVariable= 120
                var dstChecking = moment(gig.dateStart).set({hour:2,minute:0,second:0,millisecond:0})
                if(dstChecking.isAfter(gig.dateStart)){
                    gig.dateStart = gig.dateStart.subtract(dstVariable,"minute")
                    gig.dateEnd = gig.dateEnd.subtract(dstVariable,"minute")
                }
                var iscurrentDate = gig.dateStart.isSame(new Date(), "day");
               
                if(iscurrentDate) {
                    todayGigs.push(gig)
                }
            })
            console.log("weh", todayGigs)
            this.setState({ data: contain, todaysGigs: todayGigs });
            this.setState({ isLoading: false });
        });
        // agent.Profile.getMusicianDashboard(id, start, end).then(result => {
        //     this.setState({ data: result });
        //     this.setState({ isLoading: false });
        // });
    }
    handleFilterChange = value => {
        if (value === "today") {
            let today = this.formatDate(new Date());

            this.setState({ isLoading: true });
            this.handleMusicianLoad(this.currentUserId, today + " 00:00:00", today + " 23:59:59.999")
        } else if (value === "week") {
            let curr = new Date(); // get current date
            let calculateLast = new Date(); // get current date
            let first = curr.getDate() - curr.getDay() + 1; // First day is the day of the month - the day of the week
            let last = first + 6; // last day is the first day + 6

            let firstDay = new Date(curr.setDate(first));
            let lastDay = new Date(calculateLast.setDate(last));

            firstDay = this.formatDate(firstDay) + " 00:00:00";
            lastDay = this.formatDate(lastDay) + " 23:59:59.999";

            this.setState({ isLoading: true });
            this.handleMusicianLoad(this.currentUserId, firstDay, lastDay)
        } else if (value === "month") {
            let date = new Date();

            let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
            let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            firstDay = this.formatDate(firstDay) + " 00:00:00";
            lastDay = this.formatDate(lastDay) + " 23:59:59.999";

            this.setState({ isLoading: true });
            this.handleMusicianLoad(this.currentUserId, firstDay, lastDay)
        } else if (value === "nextMonth") {
            let date = new Date();

            let firstDay = new Date(date.getFullYear(), date.getMonth() + 1, 1);
            let lastDay = new Date(date.getFullYear(), date.getMonth() + 2, 0);
            firstDay = this.formatDate(firstDay) + " 00:00:00";
            lastDay = this.formatDate(lastDay) + " 23:59:59.999";

            this.setState({ isLoading: true });
            this.handleMusicianLoad(this.currentUserId, firstDay, lastDay)
        }
    };

    render() {
        const { isLoading } = this.state;
        const { currentProfile } = this.props.profileStore;
        var decoded = null
        if(this.props.commonStore.token){
            decoded = jwtDecode(this.props.commonStore.token);
        }

        const taxCode = currentProfile && currentProfile.tax && currentProfile.tax != "CUSTOM" ? currentProfile.tax : "Tax";

        const taxText =  currentProfile?.taxValue && parseInt(currentProfile?.taxValue) > 0 ? ` (ex ${taxCode})` : "";

        return (
            <div className="musician-edit-form">
                <Header />
                <Sidebar />
                <MainContainer>
                    <Hidden smDown>
                        <Heading title="Dashboard">
                            <DashboardFilter
                                onChange={this.handleFilterChange}
                                onCustomStartDateChange={this.onCustomStartDateChange}
                                onCustomEndDateChange={this.onCustomEndDateChange}
                                onCustomMonthChange={this.onCustomMonthChange}
                            />
                        </Heading>

                        <Grid container spacing={16} style={{ position: "relative" }}>
                            <Loading showed={isLoading} />
                            <Grid item xs={12}>
                                <Grid container spacing={16}>
                                    <Grid item md={4} xs={12}>
                                        <DashboardBigInformationWidget
                                            headerContent={this.state.data.totalGigs === undefined ? "Loading..." : this.state.data.totalGigs}
                                            headerCaption="Total Gigs"
                                            content1={this.state.data.upcomingGigs === undefined ? "Loading..." : this.state.data.upcomingGigs}
                                            caption1="Upcoming Gigs"
                                            content2={this.state.data.completedGigs === undefined ? "Loading..." : this.state.data.completedGigs}
                                            caption2="Completed Gigs"
                                            primaryColor={true}
                                        />
                                    </Grid>
                                    <Grid item md={4} xs={12}>
                                        {!ENABLE_SUBSCRIPTION || decoded.scope.includes(currentProfile.typeString) ? (
                                            <DashboardBigInformationWidgetSingle
                                                id="musician-md-total-gig-fee"
                                                headerContent={
                                                    (currentProfile && currentProfile.currencySymbol ? currentProfile.currencySymbol : "$") +
                                                    (this.state.data.gigFees === undefined ? "Loading..." : this.state.data.gigFees.toLocaleString())
                                                }
                                                headerCaption={`My Income${taxText}`}
                                                primaryColor={false}
                                                // content1={
                                                //     (currentProfile && currentProfile.currencySymbol ? currentProfile.currencySymbol : "$") +
                                                //     (this.state.data.outstandingFees === undefined
                                                //         ? "Loading..."
                                                //         : this.state.data.outstandingFees.toLocaleString())
                                                // }
                                                // caption1="Outstanding Fees"
                                                // content2={
                                                //     (currentProfile && currentProfile.currencySymbol ? currentProfile.currencySymbol : "$") +
                                                //     (this.state.data.receivedFees === undefined
                                                //         ? "Loading..."
                                                //         : this.state.data.receivedFees.toLocaleString())
                                                // }
                                                // caption2="Received Fees"
                                                // primaryColor={false}
                                            />
                                        ) : (
                                            <DashboardSubscriptionInformation />
                                        )}
                                    </Grid>
                                    <Grid item md={4} xs={12}>
                                        <Grid container spacing={16}>
                                            <Grid item xs={12}>
                                                <DashboardSmallInformationWidget
                                                    mhIcon="mh-microphone"
                                                    information={
                                                        this.state.data.newGigAlerts === undefined ? "Loading..." : this.state.data.newGigAlerts
                                                    }
                                                    informationDetail="New Booking Requests"
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <DashboardSmallInformationWidget
                                                    mhIcon="fa fa-eye"
                                                    information={
                                                        this.state.data.profileViews === undefined
                                                            ? "0"
                                                            : this.state.data.profileViews
                                                    }
                                                    informationDetail="Profile Views"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={16}>
                                    <Grid item md={4} xs={12}>
                                        <GigsWidget todaysGigs={this.state.todaysGigs} />
                                    </Grid>

                                    <Grid item md={8} xs={12}>
                                        <UpcomingBookingMusician tableData={this.state.data.upcomingBookings} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Hidden>

                    <Hidden mdUp>
                        <Grid container spacing={16}>
                            <Grid item xs={12}>
                                <GigsWidget todaysGigs={this.state.todaysGigs} />
                            </Grid>

                            <Grid item xs={12} style={{ marginBottom: "-8px" }}>
                                <Heading title="Dashboard">
                                    <DashboardFilter
                                        onChange={this.handleFilterChange}
                                        onCustomStartDateChange={this.onCustomStartDateChange}
                                        onCustomEndDateChange={this.onCustomEndDateChange}
                                        onCustomMonthChange={this.onCustomMonthChange}
                                    />
                                </Heading>
                            </Grid>
                        </Grid>

                        <Grid container spacing={16} style={{ position: "relative" }}>
                            <Loading showed={isLoading} />
                            <Grid item xs={12}>
                                <Grid container spacing={16}>
                                    <Grid item md={4} xs={12}>
                                        <DashboardBigInformationWidget
                                            headerContent={this.state.data.totalGigs === undefined ? "Loading..." : this.state.data.totalGigs}
                                            headerCaption="Total Gigs"
                                            content1={this.state.data.upcomingGigs === undefined ? "Loading..." : this.state.data.upcomingGigs}
                                            caption1="Upcoming Gigs"
                                            content2={this.state.data.completedGigs === undefined ? "Loading..." : this.state.data.completedGigs}
                                            caption2="Completed Gigs"
                                            primaryColor={true}
                                        />
                                    </Grid>
                                    <Grid item md={4} xs={12}>
                                        <DashboardBigInformationWidget
                                            id="musician-sm-total-gig-fee"
                                            headerContent={
                                                (currentProfile && currentProfile.currencySymbol ? currentProfile.currencySymbol : "$") +
                                                (this.state.data.gigFees === undefined ? "Loading..." : this.state.data.gigFees.toLocaleString())
                                            }
                                            headerCaption="Total Gig Fees"
                                            // content1={
                                            //     (currentProfile && currentProfile.currencySymbol ? currentProfile.currencySymbol : "$") +
                                            //     (this.state.data.outstandingFees === undefined
                                            //         ? "Loading..."
                                            //         : this.state.data.outstandingFees.toLocaleString())
                                            // }
                                            // caption1="Outstanding Fees"
                                            // content2={
                                            //     (currentProfile && currentProfile.currencySymbol ? currentProfile.currencySymbol : "$") +
                                            //     (this.state.data.receivedFees === undefined
                                            //         ? "Loading..."
                                            //         : this.state.data.receivedFees.toLocaleString())
                                            // }
                                            // caption2="Received Fees"
                                            primaryColor={false}
                                            noFeeWidget={true}
                                            style={{ height: '100%' }}
                                        />
                                    </Grid>
                                    <Grid item md={4} xs={12}>
                                        <Grid container spacing={16}>
                                            <Grid item xs={12}>
                                                <DashboardSmallInformationWidget
                                                    mhIcon="mh-microphone"
                                                    information={
                                                        this.state.data.newGigAlerts === undefined ? "Loading..." : this.state.data.newGigAlerts
                                                    }
                                                    informationDetail="New Booking Requests"
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <DashboardSmallInformationWidget
                                                    mhIcon="fa fa-eye"
                                                    information={
                                                        this.state.data.profileViews === undefined
                                                            ? "0"
                                                            : this.state.data.profileViews
                                                    }
                                                    informationDetail="Profile Views"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12}>
                                <UpcomingBookingMusician tableData={this.state.data.upcomingBookings} />
                            </Grid>
                        </Grid>
                    </Hidden>
                </MainContainer>
            </div>
        );
    }
}

export default withRouter(Musician);
