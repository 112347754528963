import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import ResponsiveTable from "../../table/ResponsiveTable";
import TableRowProfile from "../../util/TableRowProfile";
import { ExpansionPanel, ExpansionPanelSummary, Typography, ExpansionPanelDetails, Button, Grid,Menu, MenuItem } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Promise } from "bluebird";
import ConfirmationModal from "../../modal/ConfirmationModal";
import { Link, withRouter } from "react-router-dom";
import { ProfileRelationType } from "../../../types/enum";
import image from "../../../helper/image";
import blankProfile from "../../../img/hotgig-loading.png";
import moment from "moment"
@inject("venueActStore", "templateStore", "userInvitationStore","profileStore")
@withRouter
@observer
class StaffActRosterTable extends Component {
    state = {
        requests: undefined,
        rosters: undefined,
        removeRequestConfirmationModalState: false,
        removeManyRequestConfirmationModalState: false,
        removeVenueFavouriteActConfirmationModalState: false,
        removeVenueFavouriteActsConfirmationModalState: false,
        removeInvitationConfirmationModalState: false,
        cancelBookingConfirmationState: false,
        openDetail: [],
        actionAnchor: []
    };

    pendingColumns = [
        { key: "venue", label: "Venue" },
        { key: "acts", label: "Favourite Acts" },
        { key: "status", label: "Status" },
        { key: "action", label: "" }
    ];

    columns = [{ key: "venue", label: "Venue", width: "20%" }, { key: "acts", label: "Favourite Acts", width: "80%" }];

    pendingRows = [];
    rows = [];

    extractIntialFromName = name => {
        if (!name) {
            return false;
        }
        var split = name.split(" ");
        if (split) {
            var initial = "";
            if (split[0]) {
                initial += split[0].substring(0, 1);
            }
            if (split[1]) {
                initial += split[1].substring(0, 1);
            }
            return initial.toUpperCase();
        }
    };

    handleDeclineInvitation = (invitationId, actName) => {
        this.props.userInvitationStore.declineUserInvitation(invitationId).then(() => {
            this.props.userInvitationStore.resetInvitations()
            Promise.all([
                this.props.userInvitationStore.getMyUserInvitation(ProfileRelationType.VenueAct.ordinal),
                this.props.venueActStore.getStaffPendingActRoster(),
                this.props.venueActStore.getStaffActRoster()
            ]).then(() => { 
                this.props.templateStore.openSnackbar(actName + " has been declined.");
            });
        });
    }

    handleAcceptInvitation = (data) => {
        this.props.venueActStore.acceptInvitation(data).then(() => {
            this.props.userInvitationStore.resetInvitations()
            Promise.all([
                this.props.userInvitationStore.getMyUserInvitation(ProfileRelationType.VenueAct.ordinal),
                this.props.venueActStore.getStaffPendingActRoster(),
                this.props.venueActStore.getStaffActRoster()
            ]).then(() => { 
                this.props.templateStore.openSnackbar(data.invitedAct + " has been accepted.");
            });
            
        });
    }

    mapInvitationsToRowData = () => {
        const { invitations } = this.props.userInvitationStore;

        this.pendingRows = [];

        invitations.forEach(data => {
            var row = {};

            row.venue = <TableRowProfile profileId = { data.venue?.id } name={data.venue?.fullName} avatarUrl={image.getProfileImage(data.venue)} />;

            row.acts = <TableRowProfile profileId = { data.act?.id } name={`${data.invitedAct} - ${data.invitedEmail}`} avatarUrl={data.actImage ? data.actImage : blankProfile} />;

            row.status = data.additionalData ? "Pending" : "Invited";

            if(this.props.allowBooking){
                row.action = (
                    <Fragment>
                        {data.additionalData ? 
                            <Fragment>
                                <Button size="small" color="primary" onClick={() => this.handleDeclineInvitation(data.id, data.invitedAct)}>
                                    Decline
                                </Button>
                                <Button size="small" color="primary" onClick={() => this.handleAcceptInvitation(data)}>
                                    Accept
                                </Button>
                            </Fragment> :
                            <Button size="small" color="primary" onClick={() => this.openRemoveInvitationModal(data)}>
                                Remove
                            </Button>
                    } 
                    </Fragment>
                );
    
            }else{
                row.action = (
                    ""
                );
            }
           
            row.style = {
                backgroundColor: "#EEE"
            };

            this.pendingRows.push(row);
        });
        return Promise.resolve();
    };

    mapPendingRequestToRowData = () => {
        const { pendingRequest } = this.props.venueActStore;

        if (pendingRequest) {
            pendingRequest.forEach(request => {
                var row = {};

                var venue = request && request.primaryProfile ? request.primaryProfile : {};

                var act = request && request.secondaryProfile ? request.secondaryProfile : {};

                row.venue = <TableRowProfile profileId = { venue.id } name={venue.fullName} avatarUrl={image.getProfileImage(venue)} />;

                row.acts = <TableRowProfile profileId = { act.id } name={act.fullName} avatarUrl={image.getProfileImage(act)} />;

                row.status = "Pending";
                if(this.props.allowBooking){
                    row.action = (
                        <Fragment>
                            <Button size="small" color="primary" onClick={() => this.openRemoveRequestModal(request)}>
                                Remove
                            </Button>
                        </Fragment>
                    );
                }else{
                    row.action = (
                        ""
                    );
                }
               

                row.style = {
                    backgroundColor: "#EEE"
                };

                this.pendingRows.push(row);
            });
        }
    };

    setActionAnchor = (element, id) => {
        var tempAnchor = [];
        tempAnchor[id] = element;
        this.setState({ ...this.state, actionAnchor: tempAnchor });
    };

    unsetActionAchnor = id => {
        this.setState({ ...this.state, actionAnchor: [] });
    };

    handleButtonMessage = (act) => {
        this.props.profileStore.getProfileByIdSimple(act.actAdminId).then((result) => {
            this.props.history.push({
                pathname: '/messages/new',
                state: { profile: result }
            })
        })
       
    }

    createBooking = (actId,venueId) => {
        let redirectUrl = `/booking/new?actId=${actId}&venueId=${venueId}&dateStart=${moment(new Date()).format("DD-MM-YYYY")}`
                                                
        this.props.history.push(redirectUrl)
    }
    mapActRosterToRowData = () => {
        this.rows = [];
        const { groupedVenueActRoster } = this.props.venueActStore;
        if (groupedVenueActRoster) {
            groupedVenueActRoster.forEach(venueActRoster => {
                var row = {};
                console.log(venueActRoster)
                var venue =
                    venueActRoster && venueActRoster.rosters && venueActRoster.rosters[0] && venueActRoster.rosters[0].venue
                        ? venueActRoster.rosters[0].venue
                        : {};

                var acts = "";

                venueActRoster.rosters.forEach(data => {
                    acts += data.act.fullName + ", ";
                });

                acts = acts.substr(0, acts.length - 2);

                row.venue = <TableRowProfile profileId = { venue.id } name={venue.fullName} avatarUrl={image.getProfileImage(venue)} />;

                const checkOpen = (isOpen) => {
                    let tempList = this.state.openDetail
                    if(isOpen) {
                        if(this.state.openDetail.find(x => x === venue.id)) {
                            this.setState({openDetail: this.state.openDetail.filter(x => x !== venue.id)})
                        } else {
                            tempList.push(venue.id)
                            this.setState({openDetail: tempList})
                        }
                    } else {
                        this.setState({openDetail: this.state.openDetail.filter(x => x !== venue.id)})
                    }
                }
                if(this.props.allowBooking){
                    row.acts = (
                        <ExpansionPanel
                            style={{
                                boxShadow: "none",
                            }}
                            onChange={(event, ex) => checkOpen(ex)}
    
                            >
                            <ExpansionPanelSummary
                                IconButtonProps={{
                                    component: () => 
                                    <Button className="pull-right"
                                        size="small"
                                        style={{backgroundColor: '#ff0c9c', color: 'white', minWidth: 80 }}
                                        >
                                        {this.state.openDetail.find(x => x === venue.id) ? "Hide" : "View All"}
                                    </Button>
                                    }}
                                style={{...row.style, paddingLeft: 0}} 
                                expandIcon={true}
                                >
                                <Typography style={{             
                                    maxWidth: '95%',
                                    display: '-webkit-box',
                                    WebkitBoxOrient: 'vertical',
                                    WebkitLineClamp: 2,
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',                                
                                }}>
                                    {acts}
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails style={{...row.style, paddingLeft: 0}}>
                                <Grid container spacing={9}>
                                    {venueActRoster.rosters.map(roster => (
                                        
                                        <Grid item xs={12} style={{marginBottom:25, marginTop:25}}>
                                            <Grid container>
                                                <Grid item xs={12} lg={3}>
                                                    <TableRowProfile
                                                        profileId = { roster.act.id }
                                                        name={`${roster.act.fullName}`}
                                                        avatarUrl={image.getProfileImage(roster.act)}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} lg={2}>
                                                    <div style={{marginTop:15}}>
                                                         {`${roster.act.actTypeName ? roster.act.actTypeName : "-"}`}
                                                    </div>
                                                   
                                                </Grid>
                                                <Grid item xs={12} lg={1}>
                                                    <div style={{marginTop:15}}>
                                                         {`${roster.act.lineupCountName ? roster.act.lineupCountName : "-"}`}
                                                    </div>
                                                   
                                                </Grid>
                                                <Grid item xs={12} lg={3}>
                                                    <div style={{marginTop:15}}>
                                                         {`${roster.act.vibesName ? roster.act.vibesName : "-"}`}
                                                    </div>
                                                   
                                                </Grid>
                                                <Grid item xs={12} lg={1}>
                                                    <div style={{marginTop:15}}>
                                                         {`${roster.act.contactPhone ? roster.act.contactPhone : "-"}`}
                                                    </div>
                                                   
                                                </Grid>
                                                {/* <Grid item xs={12} lg={1}>
                                                    <div style={{marginTop:15}}>
                                                         Accepted
                                                    </div>
                                                   
                                                </Grid> */}
                                                <Grid item xs={12} lg={1}>
                                                    <Fragment>
                                                        <Button style={{marginTop:20}} color="primary" onClick={e => this.setActionAnchor(e.currentTarget, roster.id)}>
                                                        <i class="fa-solid fa-ellipsis-vertical fa-xl"></i>
                                                                    </Button>
                                                                    
                                                                        <Menu
                                                                            open={this.state.actionAnchor[roster.id] != null}
                                                                            onClose={() => this.unsetActionAchnor(roster.id)}
                                                                            anchorEl={this.state.actionAnchor[roster.id]}
                                                                        >
                                                                            <MenuItem 
                                                                                button
                                                                                className="booking-details"
                                                                                onClick={() => this.createBooking(roster.act?.id, venueActRoster.venueId)}
                                                                            >Create Booking</MenuItem>
                                                                            
                                                                                <MenuItem onClick={() => this.handleButtonMessage(roster.act)}>Send Message</MenuItem>
                                                                                
                                                                                <MenuItem onClick={() => this.openRemoveVenueFavouriteActModal(roster)}>Remove</MenuItem>
                                                 
                                                                        </Menu>
                                                                    
                                                    </Fragment>
                                                    {/* <Button
                                                        className="pull-right"
                                                        size="small"
                                                        color="primary"
                                                        onClick={() => this.openRemoveVenueFavouriteActModal(roster)}
                                                    >
                                                        Remove
                                                    </Button> */}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Grid>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    );
                }else{
                    row.acts = (
                        <ExpansionPanel
                            style={{
                                boxShadow: "none",
                            }}
                            onChange={(event, ex) => checkOpen(ex)}
    
                            >
                            <ExpansionPanelSummary
                                IconButtonProps={{
                                    component: () => 
                                    <Button className="pull-right"
                                        size="small"
                                        style={{backgroundColor: '#ff0c9c', color: 'white', minWidth: 80 }}
                                        >
                                        {this.state.openDetail.find(x => x === venue.id) ? "Hide" : "View All"}
                                    </Button>
                                    }}
                                style={{...row.style, paddingLeft: 0}} 
                                expandIcon={true}
                                >
                                <Typography style={{             
                                    maxWidth: '95%',
                                    display: '-webkit-box',
                                    WebkitBoxOrient: 'vertical',
                                    WebkitLineClamp: 2,
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',                                
                                }}>
                                    {acts}
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails style={{...row.style, paddingLeft: 0}}>
                                <Grid container spacing={8}>
                                    {venueActRoster.rosters.map(roster => (
                                        <Grid item xs={12}>
                                            <Grid container>
                                                <Grid item xs={12} lg={6}>
                                                    <TableRowProfile
                                                        profileId = { roster.act.id }
                                                        name={roster.act.fullName}
                                                        avatarUrl={image.getProfileImage(roster.act)}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Grid>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    );
                }
               

                this.rows.push(row);
            });
        }
    };

    openRemoveInvitationModal = request => {
        this.setState({ ...this.state, removeInvitationConfirmationModalState: true, requests: request });
    };

    openRemoveRequestModal = request => {
        this.setState({ ...this.state, removeRequestConfirmationModalState: true, requests: request });
    };

    openRemoveVenueFavouriteActModal = roster => {
        this.setState({ ...this.state, removeVenueFavouriteActConfirmationModalState: true, rosters: roster });
    };

    closeConfirmationModal = () => {
        this.setState({
            ...this.state,
            requests: undefined,
            rosters: undefined,
            removeRequestConfirmationModalState: false,
            removeVenueFavouriteActConfirmationModalState: false,
            removeVenueFavouriteActsConfirmationModalState: false,
            removeInvitationConfirmationModalState: false,
            cancelBookingConfirmationState: false
        });
    };

    handleRemoveInvitation = () => {
        this.props.templateStore.showLoadingScreen();
        this.props.userInvitationStore.deleteInvitation(this.state.requests).then(response => {
            this.props.userInvitationStore.getMyUserInvitation(ProfileRelationType.VenueAct.ordinal).then(() => {
                this.props.templateStore.openSnackbar(
                    `${this.state.requests.invitedAct} has been removed from ${this.state.requests.venue.profileName}'s favourite acts.`
                );
                this.props.templateStore.hideLoadingScreen();
                this.closeConfirmationModal();
            });
        });
    };

    handleRemoveRequest = () => {
        this.props.templateStore.showLoadingScreen();
        this.props.venueActStore.removeRequest(this.state.requests.id).then(response => {
            this.props.templateStore.openSnackbar(
                `${response.secondaryProfile.fullName} has been removed from ${response.primaryProfile.fullName}'s favourite acts.`
            );
            this.props.templateStore.hideLoadingScreen();
            this.closeConfirmationModal();
        });
    };

    handleRemoveManyRequests = () => {
        this.props.templateStore.showLoadingScreen();
        this.props.venueActStore.removeManyRequest(this.state.requests).then(response => {
            this.props.templateStore.openSnackbar(`All ${response.secondaryProfile.fullName}'s favourite act requests has been removed.`);
            this.props.templateStore.hideLoadingScreen();
            this.closeConfirmationModal();
        });
    };

    handleRemoveFavouriteAct = () => {
        this.setState({ removeVenueFavouriteActConfirmationModalState: false, cancelBookingConfirmationState: true });
    };

    executeRemoveFavouriteAct = () => {
        this.props.templateStore.showLoadingScreen();
        this.props.venueActStore.deleteVenueAct(this.state.rosters.id, false).then(response => {
            this.props.templateStore.openSnackbar(`${this.state.rosters.act.fullName} has been removed from ${this.state.rosters.venue.fullName}'s favourite acts.`);
            this.props.templateStore.hideLoadingScreen();
            this.closeConfirmationModal();
        });
    };

    executeRemoveFavouriteActAndCancelBooking = () => {
        this.props.templateStore.showLoadingScreen();
        this.props.venueActStore.deleteVenueAct(this.state.rosters.id, true).then(response => {
            this.props.templateStore.openSnackbar(`${this.state.rosters.act.fullName} has been removed from ${this.state.rosters.venue.fullName}'s favourite acts.`);
            this.props.templateStore.hideLoadingScreen();
            this.closeConfirmationModal();
        });
    };

    handleRemoveFavouriteActs = () => {
        this.props.templateStore.showLoadingScreen();
        this.props.venueActStore.deleteManyVenueAct(this.state.rosters).then(response => {
            this.props.templateStore.openSnackbar(`All ${this.state.rosters.venue.fullName}'s favourite acts has been removed.`);
            this.props.templateStore.hideLoadingScreen();
            this.closeConfirmationModal();
        });
    };

    render() {
        this.mapInvitationsToRowData();
        this.mapPendingRequestToRowData();
        this.mapActRosterToRowData();
        return (
            <Fragment>
                {this.pendingRows.length > 0 ? (
                    <Fragment>
                        <ResponsiveTable
                            columns={this.pendingColumns}
                            rows={this.pendingRows}
                            showToolbar={true}
                            title="Pending Act Request"
                            pagination={this.pendingRows.length > 5}
                        />
                        <br />
                    </Fragment>
                ) : (
                    ""
                )}
                <ResponsiveTable columns={this.columns} rows={this.rows} />

                <ConfirmationModal
                    title={"Remove Request"}
                    open={this.state.removeInvitationConfirmationModalState}
                    message={`Are you sure to delete this act?`}
                    closeHandler={this.closeConfirmationModal}
                    confirmationLabel="Remove"
                    confirmationHandler={this.handleRemoveInvitation}
                />

                <ConfirmationModal
                    title={"Remove Request"}
                    open={this.state.removeRequestConfirmationModalState}
                    message={`Are you sure to delete this act??`}
                    closeHandler={this.closeConfirmationModal}
                    confirmationLabel="Remove"
                    confirmationHandler={this.handleRemoveRequest}
                />
                <ConfirmationModal
                    title={"Remove Favourite Act"}
                    open={this.state.removeVenueFavouriteActConfirmationModalState}
                    message={`Are you sure you want to remove ${this.state.rosters?.act?.actName} from your act roster?`}
                    closeHandler={this.closeConfirmationModal}
                    confirmationLabel="Remove"
                    confirmationHandler={this.handleRemoveFavouriteAct}
                />
                <ConfirmationModal
                    title={"Cancel Bookings"}
                    open={this.state.cancelBookingConfirmationState}
                    message={`Do you want to cancel current bookings related with this act?`}
                    declineHandler={this.executeRemoveFavouriteAct}
                    closeHandler={this.closeConfirmationModal}
                    confirmationLabel="Remove and Cancel Bookings"
                    declineLabel="Remove and Keep Bookings"
                    maxWidth="sm"
                    confirmationHandler={this.executeRemoveFavouriteActAndCancelBooking}
                />
            </Fragment>
        );
    }
}

export default StaffActRosterTable;
